<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>
          <span v-if="create">{{ $tfo(['common.create', 'common.webhook']) }}</span>
          <span v-else>{{ $tfo(['common.edit', 'common.webhook']) }}</span>
        </h2>
      </div>
    </div>

    <el-form
      v-show="tab === 'info'"
      v-loading="loading"
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      ref="form"
      :name="$options.name"
      :model="composite"
      :rules="rules"
    >
      <el-form-item :label="$tfo('webhook_id')" v-if="item.id">
        <span name="id">{{ item.id }}</span>
      </el-form-item>
      <el-form-item :label="$tfo('webhook_title')" prop="name">
        <el-input name="name" v-model="item.name"></el-input>
      </el-form-item>
      <el-form-item :label="$tf('common.url')" prop="url">
        <el-input name="url" v-model="item.url"></el-input>
      </el-form-item>
      <el-form-item :label="$tf('common.batch_size')" prop="batch_size">
        <el-input name="batch_size" v-model="item.batch_size"></el-input>
      </el-form-item>
      <el-form-item :label="$tf('common.send_attempts')" prop="send_attempts">
        <el-input name="send_attempts" v-model="item.send_attempts"></el-input>
        <div class="parameter-description">{{ $tf('common.send_attempts_comment') }}</div>
      </el-form-item>
      <el-form-item :label="$tf('common.filters')" prop="filters">
        <el-input name="filters" type="textarea" v-model="filters" rows="8" @blur="blurFiltersHandler"></el-input>
      </el-form-item>
      <el-form-item prop="active">
        <el-checkbox name="active" v-model="item.active">{{ $tf('active') }}</el-checkbox>
      </el-form-item>
    </el-form>

    <div class="mar-top--large">
      <el-button
        name="save-btn"
        type="primary"
        @click="saveHandler"
        :disabled="create ? $hasNoPermission('ffsecurity.add_webhook') : $hasNoPermission('ffsecurity.change_webhook')"
        >{{ $tf('common.save') }}</el-button
      >
      <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
    </div>

    <div class="mar-top--large">
      <confirm-button target="webhook" size="mini" plain @confirm="deleteHandler" :disabled="$hasNoPermission('ffsecurity.delete_webhook')" v-if="!create">
      </confirm-button>
    </div>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import ConfirmButton from '../confirm/confirm-button';

let baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  url: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  filters: [{ trigger: 'blur', validator: validateJson }]
};

function validateJson(rule, value, callback) {
  try {
    JSON.parse(value);
    callback();
  } catch (e) {
    callback(new Error(e));
  }
}

export default {
  name: 'hooks-form',
  components: { ConfirmButton },
  data: function () {
    return {
      validateJson,
      tab: 'info',
      rules: this.$applyRuleMessages(baseRules),
      item: {},
      tfilters: '',
      loading: false
    };
  },
  computed: {
    create() {
      return !this.$route.params.id;
    },
    state() {
      return this.$store.state.hooks;
    },
    composite() {
      return {
        filters: this.filters,
        name: this.item.name,
        id: this.item.id,
        url: this.item.url,
        active: this.item.active
      };
    },
    filters: {
      get: function () {
        return this.tfilters || JSON.stringify(this.item.filters, null, '\t');
      },
      set: function (v) {
        this.tfilters = v;
      }
    }
  },
  created() {
    let id = this.$route.params.id;
    this.item = _.cloneDeep(this.state.item.empty);

    if (id) {
      this.loading = true;
      this.$store
        .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
        .then((v) => {
          this.item = v;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$store.dispatch(this.$store.state.hooks.Action.Get);
    this.$store.dispatch(this.$store.state.groups.Action.Get);
  },
  methods: {
    afterCreate() {
      this.$router.push({ path: '/hooks/' + this.item.id + '/' });
    },
    blurFiltersHandler() {
      try {
        this.item.filters = JSON.parse(this.tfilters);
        this.tfilters = '';
        return true;
      } catch (e) {
        return false;
      }
    },
    previewFileHandler() {},
    deleteFileHandler() {},
    saveHandler(e) {
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.save(this.item);
    },
    save(updatedItem) {
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, updatedItem)
        .then((v) => {
          this.item.id = v.id;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: `/hooks/${v.id}/` });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteHandler(e) {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/hooks/' });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler(e) {
      this.$router.backTo({ path: '/hooks/' });
    },
    changePermissionHandler(group, v) {
      this.$set(this.item.permissions, group.id, v);
      if (!v) this.item.permissions[group.id] = null;
    }
  }
};
</script>
